import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import "./eventCalendar.css";
import moment from "moment";
import { debounce } from "lodash";
import { SidebarContext } from "contexts/SidebarContext";

function EventCalendar(props) {
  const {
    calendarData,
    initialDate,
    yearUpdate,
    getValueMonthChange,
    selectShow,
    monthUpdate,
  } = props;
  const { sidebarWidth } = useContext(SidebarContext);
  const calendarRef = useRef(null);
  const calendarApi = calendarRef?.current?.getApi();
  useEffect(() => {
    if (monthUpdate !== undefined && calendarApi && yearUpdate) {
      const monthChange = moment()
        .month(monthUpdate)
        .year(yearUpdate)
        .startOf("month")
        .format("YYYY-MM-DD");
      calendarApi.gotoDate(monthChange);
      calendarApi.setOption("validRange", {
        start: new Date().toISOString().slice(0, 10),
      });
    }
  }, [monthUpdate, calendarApi, yearUpdate, calendarRef]);
  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 150);
  }, [sidebarWidth]);
  const renderDayCell = ({ date }) => {
    return <div className="fc-daygrid-day-top fc-day">{date.getDate()}</div>;
  };
  const renderEventContent = ({ event }) => {
    return (
      <div
        className="fc-event-content"
        style={{
          backgroundColor: `${
            event.extendedProps.status === 1
              ? event.extendedProps.show.color
              : event.extendedProps.status === 2
              ? "#58595b"
              : "#fb3838"
          }`,
          cursor: `${
            event.extendedProps.status === 3 ? "not-allowed" : "pointer"
          }`,
        }}
      >
        <div className="fc-event-title">
          {event.extendedProps.show.name} - {event.extendedProps.venue.name} - (
          {event.extendedProps.available} / {event.extendedProps.total}) -{" "}
          {event.extendedProps.show_time}
        </div>
      </div>
    );
  };
  const debouncedGetValueMonthChange = useMemo(() => {
    return debounce((val, val1) => {
      getValueMonthChange(val, val1, true);
    }, 200);
  }, [getValueMonthChange]);

  const datesSet = useCallback(
    (arg) => {
      const newMonth = arg.view.currentStart.getMonth();
      const newYear = new Date(arg.view.currentStart).getFullYear();
      debouncedGetValueMonthChange(newMonth, newYear);
    },
    [debouncedGetValueMonthChange, monthUpdate, yearUpdate]
  );
  return (
    <FullCalendar
      ref={calendarRef}
      plugins={[dayGridPlugin, interactionPlugin]}
      initialView="dayGridMonth"
      initialDate={initialDate}
      events={calendarData}
      editable={false}
      height="auto"
      eventClick={(info) =>
        selectShow(info.event.id, info.event.extendedProps.status)
      }
      lazyFetching={true}
      dayCellContent={renderDayCell}
      eventContent={renderEventContent}
      firstDay={1}
      datesSet={(arg) => {
        datesSet(arg);
      }}
      dayCellClassNames="testx"
      dayHeaderClassNames="texty"
      handleWindowResize={true}
      expandRows={true}
    />
  );
}

export default EventCalendar;
