import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import React, { useEffect, useState } from 'react';
import { Element } from 'react-scroll';
import { useParams } from 'react-router-dom';
import { useUserActions } from 'hooks/useUserActions';
import moment from 'moment';
import DataTableUnitUser from 'views/Applications/DataTableUnitUser';

function Settings() {
  const textColorTitle = useColorModeValue('gray.700', 'white');
  const { user_id } = useParams();
  const [userDetail, setUserDetail] = useState({});
  const { colorMode } = useColorMode();
  const userActions = useUserActions();
  const token = localStorage.getItem('user');
  useEffect(() => {
    if (user_id) {
      userActions.getUserById(user_id).then((res) => {
        if (res.status === 200) {
          setUserDetail(res.data.data);
        }
      });
    }
  }, [token.refresh_token]);
  const [activeButtons, setActiveButtons] = useState({
    messages: true,
    social: false,
  });
  const textColor = useColorModeValue('gray.400', 'white');
  return (
    <Tabs variant='unstyled'>
      <Box w='100%'>
        <Stack
          direction={{ sm: 'column', lg: 'row' }}
          spacing={{ sm: '8px', lg: '38px' }}
          w={{ sm: '100%', lg: null }}
        >
          <TabList>
            <Tab _focus={{}}>
              <Button
                variant={
                  activeButtons.messages
                    ? colorMode === 'light'
                      ? 'light'
                      : 'primary'
                    : 'no-effects'
                }
                boxShadow={
                  activeButtons.messages
                    ? '0px 2px 5.5px rgba(0, 0, 0, 0.06)'
                    : 'none'
                }
                transition='all .5s ease'
                w={{ sm: '100%', lg: '135px' }}
                h='35px'
                _focus={{ boxShadow: '0px 2px 5.5px rgba(0, 0, 0, 0.06)' }}
                _active={{
                  boxShadow:
                    activeButtons.messages &&
                    '0px 2px 5.5px rgba(0, 0, 0, 0.06)',
                }}
                onClick={() =>
                  setActiveButtons({
                    messages: true,
                    social: false,
                    notifications: false,
                    backup: false,
                  })
                }
              >
                <Text
                  color={!activeButtons.messages && 'white'}
                  fontWeight='bold'
                  fontSize='xs'
                >
                  Basic Info
                </Text>
              </Button>
            </Tab>
            <Tab _focus={{}}>
              <Button
                variant={
                  activeButtons.social
                    ? colorMode === 'light'
                      ? 'light'
                      : 'primary'
                    : 'no-effects'
                }
                boxShadow={
                  activeButtons.social
                    ? '0px 2px 5.5px rgba(0, 0, 0, 0.06)'
                    : 'none'
                }
                transition='all .5s ease'
                w={{ sm: '100%', lg: '135px' }}
                h='35px'
                _focus={{ boxShadow: '0px 2px 5.5px rgba(0, 0, 0, 0.06)' }}
                _active={{
                  boxShadow:
                    activeButtons.social && '0px 2px 5.5px rgba(0, 0, 0, 0.06)',
                }}
                onClick={() =>
                  setActiveButtons({
                    messages: false,
                    social: true,
                    notifications: false,
                    backup: false,
                  })
                }
              >
                <Text
                  color={!activeButtons.social && 'white'}
                  fontWeight='bold'
                  fontSize='xs'
                >
                  Units of User
                </Text>
              </Button>
            </Tab>
          </TabList>
        </Stack>
      </Box>
      <TabPanels>
        <TabPanel p={{ sm: 0 }}>
          <Stack
            direction='column'
            spacing='24px'
            align={{ lg: 'flex-end' }}
            justify={{ lg: 'flex-end' }}
            w='100%'
          >
            <Card
              w={{ sm: '100%', lg: '70%' }}
              alignSelf='flex-start'
              justifySelf='flex-end'
            >
              <Element id='info' name='info'>
                <CardHeader mb={{ lg: '40px', sm: '10px' }}>
                  <Text color={textColorTitle} fontSize='lg' fontWeight='bold'>
                    Basic info
                  </Text>
                </CardHeader>
                <CardBody px='5px'>
                  <Flex direction='column'>
                    <Flex align='center' mb='18px'>
                      <Text
                        fontSize='md'
                        color={textColor}
                        fontWeight='normal'
                        me='10px'
                      >
                        E-mail:{' '}
                      </Text>
                      <Text fontSize='md' color='gray.800' fontWeight='400'>
                        {userDetail.email}
                      </Text>
                    </Flex>
                    <Flex align='center' mb='18px'>
                      <Text
                        fontSize='md'
                        color={textColor}
                        fontWeight='normal'
                        me='10px'
                      >
                        Full name:{' '}
                      </Text>
                      <Text fontSize='md' color='gray.800' fontWeight='400'>
                        {userDetail.fullname}
                      </Text>
                    </Flex>
                    {userDetail.password_change_at && (
                      <Flex align='center' mb='18px'>
                        <Text
                          fontSize='md'
                          color={textColor}
                          fontWeight='normal'
                          me='10px'
                        >
                          Password changed:{' '}
                        </Text>
                        <Text fontSize='md' color='gray.800' fontWeight='400'>
                          {moment(userDetail.password_change_at).format(
                            'DD/MM/YYYY'
                          )}
                        </Text>
                      </Flex>
                    )}
                    <Flex align='center' mb='18px'>
                      <Text
                        fontSize='md'
                        color={textColor}
                        fontWeight='normal'
                        me='10px'
                      >
                        Phone:{' '}
                      </Text>
                      <Text fontSize='md' color='gray.800' fontWeight='400'>
                        {userDetail.phone}
                      </Text>
                    </Flex>

                    <Flex align='center' mb='18px'>
                      <Text
                        fontSize='md'
                        color={textColor}
                        fontWeight='normal'
                        me='10px'
                      >
                        Roles:{' '}
                      </Text>
                      <Text fontSize='md' color='gray.800' fontWeight='400'>
                        {userDetail.role &&
                          userDetail?.role.reduce((accumulator, user) => {
                            if (accumulator === '') {
                              return user.name;
                            } else {
                              return `${accumulator}, ${user.name}`;
                            }
                          }, '')}
                      </Text>
                    </Flex>
                  </Flex>
                </CardBody>
              </Element>
            </Card>
          </Stack>
        </TabPanel>
        <TabPanel p={{ sm: 0 }}>
          <DataTableUnitUser userID={user_id} userDetail={userDetail} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export default Settings;
