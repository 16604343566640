import {
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
  Select,
  FormControl,
  Text,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useBookingActions } from 'hooks/useBookingActions';
import { bookingStatus, invoiceStatus } from 'variables/general';
import { BsSearch } from 'react-icons/bs';
import { RxReset } from 'react-icons/rx';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { MdOutlinePublish } from 'react-icons/md';
import DatePickerInput from 'components/CustomDateInput/CustomDateInput';
import { totalRow } from 'state/roles-permissions';

import {
  listChannelAtom,
  listShowAtom,
  listUnitAtom,
  venueListAtom,
  zoneListAtom,
  paymentStatusListAtom,
  paymentMethodListAtom,
  listInvoiceAtom,
  showTimeListAtom,
} from '../../state/show';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import SearchTable2 from 'components/Tables/SearchTable2';
import { columnsInvoiceList } from 'variables/columnsData';

function DataTableInvoice() {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('This field is required')
      .email('Invalid Email'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const textColor = useColorModeValue('gray.700', 'white');
  const bookingActions = useBookingActions();
  const listShow = useRecoilValue(listShowAtom);
  const listInvoice = useRecoilValue(listInvoiceAtom);
  const totalRows = useRecoilValue(totalRow);
  const venueList = useRecoilValue(venueListAtom);
  const listChannel = useRecoilValue(listChannelAtom);
  const listUnit = useRecoilValue(listUnitAtom);
  const listZone = useRecoilValue(zoneListAtom);
  const listPaymentMethod = useRecoilValue(paymentMethodListAtom);
  const showTimeList = useRecoilValue(showTimeListAtom);
  const listPaymentStatus = useRecoilValue(paymentStatusListAtom);
  const [isFetching, setIsFetching] = useState(true);
  const [newInvoiceList, setNewInvoiceList] = useState([]);
  const [isPublishInvoice, setIsPublishInvoice] = useState(false);
  const [listCheckedChoose, setListCheckedChoose] = useState([]);
  const [clearAllCheckBox, setClearAllCheckBox] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [refreshPagingDefault, setRefreshPagingDefault] = useState(false);
  const [currentPublish, setCurrentPublish] = useState({
    code: '',
    id: '',
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenAllInvoice,
    onOpen: onOpenAllInvoice,
    onClose: onCloseAllInvoice,
  } = useDisclosure();
  const toast = useToast();

  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    control: controlSearch,
    watch: watchSearch,
    reset: resetSearch,
    setValue: setValueSearch,
    formState,
  } = useForm();
  const { register, handleSubmit, reset } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const statusSearch = watchSearch('statusSearch');
  const channelSearch = watchSearch('channelSearch');
  const bookingCodeSearch = watchSearch('bookingCodeSearch');
  const bookerNameSearch = watchSearch('bookerNameSearch');
  const unitSearch = watchSearch('unitSearch');
  const paymentMethodSearch = watchSearch('paymentMethodSearch');
  const paymentStatusSearch = watchSearch('paymentStatusSearch');
  const showTimeSearch = watchSearch('showTimeSearch');
  const zoneSearch = watchSearch('zoneSearch');
  const venueSearch = watchSearch('venueSearch');
  const showSearch = watchSearch('showSearch');
  useEffect(() => {
    if (channelSearch) {
      bookingActions.getListUnits({ channel_id: channelSearch }, 0, '');
    }
  }, [channelSearch]);

  useEffect(async () => {
    setIsFetching(true);

    bookingActions.getListShow({ status: 1 }, 0, '');
    bookingActions.getListChannel({ status: 1 }, 0, '');
    bookingActions.getListVenue({ status: 1 }, 0, '');
    bookingActions.getListZones({ status: 1 }, 0, '');
    bookingActions.getListPaymentStatus({ status: 1 }, 0, '');
    bookingActions.getListPaymentMethod({ status: 1 }, 0, '');
    bookingActions.getListShowTime({ status: 1 }, 0, '');
    setValueSearch('from_showdate', new Date());
    setValueSearch('to_showdate', new Date());
    setIsFetching(false);
  }, []);
  useEffect(() => {
    if (listInvoice) {
      const newListInvoices = listInvoice.map((i) => ({
        ...i,
        typeSwitch: 'invoice',
        filters: {
          show_id: showSearch,
          venue_id: venueSearch,
          booking_code: bookingCodeSearch,
          booker_name: bookerNameSearch,
          channel_id: channelSearch,
          unit_id: unitSearch,
          payment_method: paymentMethodSearch,
          payment_status: paymentStatusSearch,
          show_time: showTimeSearch,
          zone_id: zoneSearch,
          status: statusSearch ? statusSearch : 1,
        },
      }));
      setNewInvoiceList(newListInvoices);
    }
  }, [listInvoice]);
  const recallApi = async (fetchDataOptions) => {
    setIsFetching(true);
    const defaultValue = new Date();
    await bookingActions.getListInvoice(
      {
        status: 0,
        show_date_from: watchSearch('from_showdate')
          ? new Date(
              watchSearch('from_showdate').setHours(0, 0, 0, 0)
            ).toISOString()
          : new Date(defaultValue.setHours(0, 0, 0, 0)).toISOString(),
        show_date_to: watchSearch('to_showdate')
          ? new Date(watchSearch('to_showdate')).toISOString()
          : new Date(defaultValue.setHours(23, 59, 59, 999)).toISOString(),
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
        show_id: showSearch,
        venue_id: venueSearch,
        booking_code: bookingCodeSearch,
        booker_name: bookerNameSearch,
        channel_id: channelSearch,
        unit_id: unitSearch,
        payment_method: paymentMethodSearch,
        payment_status: paymentStatusSearch,
        show_time: showTimeSearch,
        zone_id: zoneSearch,
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
    setIsFetching(false);
  };
  async function onSubmitSearch({
    bookingCodeSearch,
    bookerNameSearch,
    showSearch,
    venueSearch,
    statusSearch,
    channelSearch,
    unitSearch,
    paymentMethodSearch,
    paymentStatusSearch,
    showTimeSearch,
    zoneSearch,
    invoice_status,
  }) {
    setIsFetching(true);
    if (isPublishInvoice) {
      setIsPublishInvoice(false);
      onOpenAllInvoice();
    } else {
      await bookingActions.getListInvoice(
        {
          show_id: showSearch,
          venue_id: venueSearch,
          booking_code: bookingCodeSearch,
          booker_name: bookerNameSearch,
          status: statusSearch,
          channel_id: channelSearch,
          unit_id: unitSearch,
          payment_method: paymentMethodSearch,
          payment_status: paymentStatusSearch,
          show_time: showTimeSearch,
          zone_id: zoneSearch,
          invoice_status,
          show_date_from: new Date(
            watchSearch('from_showdate').setHours(0, 0, 0, 0)
          ).toISOString(),
          show_date_to: new Date(
            watchSearch('to_showdate').setHours(23, 59, 59, 999)
          ).toISOString(),
        },
        0,
        localStorage && localStorage.getItem('currentPageSizeStore')
          ? localStorage.getItem('currentPageSizeStore')
          : 10
      );
    }
    setRefreshPagingDefault(true);
    setIsFetching(false);
  }

  async function onSubmit({
    company_name,
    tax_code,
    company_address,
    email,
    invoice_issuer,
  }) {
    setIsLoading(true);
    const params = {
      booking_id: currentPublish.id,
      company_name,
      tax_code,
      company_address,
      email,
      invoice_issuer: parseInt(invoice_issuer),
    };
    try {
      const res = await bookingActions.publishInvoice(params);
      if (res.status === 200) {
        onClose();
        reset();

        toast({
          description: 'Publish Invoice success',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        await bookingActions.getListInvoice(
          {
            status: 0,
            show_date_from: new Date(
              watchSearch('from_showdate').setHours(0, 0, 0, 0)
            ).toISOString(),
            show_date_to: new Date(
              watchSearch('to_showdate').setHours(0, 0, 0, 0)
            ).toISOString(),
          },
          0,
          10
        );
      }
    } catch (err) {
      console.warn(err);
      toast({
        description:
          'Your request has been recorded and processed by VNPT. Please check the result later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
    setIsLoading(false);
  }
  const publishBulk = async () => {
    setIsLoading(true);
    try {
      const res = await bookingActions.publishBulkInvoice({
        booking_ids: listCheckedChoose.map((i) => parseInt(i)),
      });
      if (res.status === 200) {
        toast({
          description: 'Publish All Invoice success',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        onCloseAllInvoice();
        setClearAllCheckBox(true);
        await bookingActions.getListInvoice(
          {
            status: 0,
            show_date_from: watchSearch('from_showdate')
              ? new Date(watchSearch('from_showdate')).toISOString()
              : new Date().toISOString(),
            show_date_to: watchSearch('to_showdate')
              ? new Date(watchSearch('to_showdate')).toISOString()
              : new Date().toISOString(),
          },
          0,
          10
        );
      }
    } catch (error) {
      toast({
        description: 'Something wrong',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
    setIsLoading(false);
  };
  const publishInvoice = async (val) => {
    setCurrentPublish({
      id: val.id,
      code: val.code,
    });
    onOpen();
  };
  const getValueChecked = (val) => {
    if (val) {
      const checkedIDs = Object.keys(val);
      setListCheckedChoose(checkedIDs);
    }
  };
  return (
    <Card px='0px'>
      <CardHeader px='22px' mb='12px'>
        <Flex direction='column' display='block'>
          <Text
            float='left'
            color={textColor}
            fontSize='lg'
            fontWeight='bold'
            mb='6px'
          >
            Invoice Report
          </Text>
          <Modal
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={() => {
              onClose();
              reset();
            }}
            size='2xl'
          >
            <ModalOverlay />
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalContent>
                <ModalHeader color={'green'}>
                  Publish invoice booking {currentPublish.code}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                  <FormControl isRequired>
                    <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                      Company Name
                    </FormLabel>
                    <Input
                      fontSize='sm'
                      ms='4px'
                      type='text'
                      placeholder='Company Name'
                      maxLength={250}
                      size='md'
                      name='company_name'
                      {...register('company_name')}
                      isInvalid={errors.company_name}
                      errorBorderColor='red.300'
                    />
                    <Text color='red' fontSize={14} mb='10px' ml='5px'>
                      {errors.company_name?.message}
                    </Text>
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                      Tax Code
                    </FormLabel>
                    <Input
                      fontSize='sm'
                      ms='4px'
                      type='text'
                      placeholder='Tax Code'
                      maxLength={250}
                      size='md'
                      name='tax_code'
                      {...register('tax_code')}
                      isInvalid={errors.tax_code}
                      errorBorderColor='red.300'
                    />
                    <Text color='red' fontSize={14} mb='10px' ml='5px'>
                      {errors.tax_code?.message}
                    </Text>
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                      Company Address
                    </FormLabel>
                    <Input
                      fontSize='sm'
                      ms='4px'
                      type='text'
                      placeholder='Company Address'
                      maxLength={250}
                      size='md'
                      name='company_address'
                      {...register('company_address')}
                      isInvalid={errors.company_address}
                      errorBorderColor='red.300'
                    />
                    <Text color='red' fontSize={14} mb='10px' ml='5px'>
                      {errors.company_address?.message}
                    </Text>
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                      Email
                    </FormLabel>
                    <Input
                      fontSize='sm'
                      ms='4px'
                      type='text'
                      placeholder='Email'
                      maxLength={250}
                      size='md'
                      name='email'
                      {...register('email')}
                      isInvalid={errors.email}
                      errorBorderColor='red.300'
                    />
                    <Text color='red' fontSize={14} mb='10px' ml='5px'>
                      {errors.email?.message}
                    </Text>
                  </FormControl>
                  <FormControl>
                    <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                      Invoice issuer
                    </FormLabel>
                    <Select
                      ms='4px'
                      type='text'
                      defaultValue='0'
                      size='sm'
                      name='invoice_issuer'
                      {...register('invoice_issuer')}
                      width='120px'
                    >
                      <option value='0'>Default</option>
                      <option value='1'>SaiGon</option>
                      <option value='2'>Hoi An</option>
                    </Select>
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme='blue'
                    mr={3}
                    type='submit'
                    isLoading={isLoading}
                  >
                    Publish
                  </Button>
                  <Button
                    onClick={() => {
                      onClose();
                      reset();
                    }}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          </Modal>
          <Modal
            closeOnOverlayClick={false}
            isOpen={isOpenAllInvoice}
            onClose={() => {
              onCloseAllInvoice();
              reset();
            }}
            size='2xl'
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader color={'green'}>
                Publish invoice bookings
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                Do you want to publish invoices for selected Booking(s)?
              </ModalBody>
              <ModalFooter>
                <Button
                  colorScheme='blue'
                  mr={3}
                  onClick={() => publishBulk()}
                  isLoading={isLoading}
                >
                  Yes
                </Button>
                <Button
                  onClick={() => {
                    onCloseAllInvoice();
                    reset();
                  }}
                >
                  No
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Flex>
      </CardHeader>
      <CardBody>
        <Flex ml='22px'></Flex>
        <Card>
          <form
            id='booking-search-form'
            onSubmit={handleSubmitSearch(onSubmitSearch)}
          >
            <Flex
              direction='row'
              alignItems='center'
              mb={3}
              gap={4}
              flexWrap={'wrap'}
            >
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel ms='4px' fontSize='xs' fontWeight='semibold'>
                  Booking Code
                </FormLabel>
                <Input
                  _placeholder={{ color: 'gray.400' }}
                  variant='main'
                  type='text'
                  placeholder='Booking Code'
                  width='200px'
                  size='sm'
                  fontSize='sm'
                  name='bookingCodeSearch'
                  {...registerSearch('bookingCodeSearch')}
                  _focus={{ borderColor: 'blue.500' }}
                />
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel ms='4px' fontSize='xs' fontWeight='semibold'>
                  Booking information,...
                </FormLabel>
                <Input
                  _placeholder={{ color: 'gray.400' }}
                  variant='main'
                  type='text'
                  placeholder='Booking information,...'
                  minW='80px'
                  maxW='185px'
                  size='sm'
                  fontSize='sm'
                  name='bookerNameSearch'
                  {...registerSearch('bookerNameSearch')}
                  _focus={{ borderColor: 'blue.500' }}
                />
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                  Show
                </FormLabel>
                <Select
                  ms='4px'
                  type='text'
                  defaultValue='0'
                  size='sm'
                  id='showSearch'
                  name='showSearch'
                  {...registerSearch('showSearch')}
                  width='100px'
                >
                  <option value=''>All</option>
                  {listShow.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                  Venue
                </FormLabel>
                <Select
                  ms='4px'
                  type='text'
                  size='sm'
                  id={'venueSearch'}
                  name='venueSearch'
                  {...registerSearch('venueSearch')}
                >
                  <option value=''>All</option>
                  {venueList.map((i) => (
                    <option key={i.id} value={i.id}>
                      {i.name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                  Zone
                </FormLabel>
                <Select
                  ms='4px'
                  type='text'
                  size='sm'
                  name='zoneSearch'
                  {...registerSearch('zoneSearch')}
                  width='200px'
                >
                  <option value=''>All</option>
                  {listZone.map((i) => (
                    <option key={i.id} value={i.id}>
                      {i.name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                  Channel
                </FormLabel>
                <Select
                  ms='4px'
                  type='text'
                  size='sm'
                  name='channelSearch'
                  {...registerSearch('channelSearch')}
                  width='200px'
                >
                  <option value=''>All</option>
                  {listChannel.map((i) => (
                    <option key={i.id} value={i.id}>
                      {i.name}
                    </option>
                  ))}
                </Select>
              </Box>
              {channelSearch && (
                <Box direction='row' alignItems='center' display='block'>
                  <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                    Unit
                  </FormLabel>
                  <Select
                    ms='4px'
                    type='text'
                    size='sm'
                    id='unit_search'
                    name='unitSearch'
                    {...registerSearch('unitSearch')}
                  >
                    <option value=''>All</option>
                    {listUnit.map((i) => (
                      <option key={i.id} value={i.id}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </Box>
              )}
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                  Show Date From
                </FormLabel>
                <Controller
                  name='from_showdate'
                  control={controlSearch}
                  render={({ field }) => (
                    <DatePickerInput
                      onChange={(value) => field.onChange(value)}
                      mask='99/99/9999'
                      selected={watchSearch('from_showdate')}
                      selectsStart
                      endDate={watchSearch('to_showdate')}
                      minDate={new Date('01/01/2020')}
                    />
                  )}
                />
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                  Show Date To
                </FormLabel>
                <Controller
                  name='to_showdate'
                  control={controlSearch}
                  render={({ field }) => (
                    <DatePickerInput
                      onChange={(value) => field.onChange(value)}
                      selected={field.value}
                      mask='99/99/9999'
                      selectsEnd
                      endDate={watchSearch('to_showdate')}
                      minDate={watchSearch('from_showdate')}
                    />
                  )}
                />
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                  Show Time
                </FormLabel>
                <Select
                  ms='4px'
                  type='text'
                  defaultValue=''
                  size='sm'
                  name='showTimeSearch'
                  {...registerSearch('showTimeSearch')}
                >
                  <option value=''>All</option>
                  {showTimeList.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                  Booking Status
                </FormLabel>
                <Select
                  ms='4px'
                  type='text'
                  defaultValue='0'
                  size='sm'
                  id='statusSearch'
                  name='statusSearch'
                  {...registerSearch('statusSearch')}
                  width='100px'
                >
                  {bookingStatus.map((item, index) => (
                    <option key={index} value={item.Id}>
                      {item.Name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                  Invoice Status
                </FormLabel>
                <Select
                  ms='4px'
                  type='text'
                  defaultValue='0'
                  size='sm'
                  name='invoice_status'
                  {...registerSearch('invoice_status')}
                  width='100px'
                >
                  {invoiceStatus.map((item, index) => (
                    <option key={index} value={item.Id}>
                      {item.Name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                  Payment Method
                </FormLabel>
                <Select
                  ms='4px'
                  type='text'
                  placeholder=''
                  size='sm'
                  name='paymentMethodSearch'
                  {...registerSearch('paymentMethodSearch')}
                >
                  <option value=''>All</option>
                  {listPaymentMethod.map((item, index) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel
                  htmlFor='role-search-lbl'
                  fontWeight='semibold'
                  fontSize='xs'
                  ms='4px'
                >
                  Payment Status
                </FormLabel>
                <Select
                  ms='4px'
                  type='text'
                  defaultValue=''
                  size='sm'
                  name='paymentStatusSearch'
                  {...registerSearch('paymentStatusSearch')}
                  width='100px'
                >
                  <option value=''>All</option>
                  {listPaymentStatus.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </Box>
            </Flex>
            <Text color='red' fontSize={14}>
              {Object.values(errors)[0]?.message}
            </Text>
            <Flex justify='center' align='center' gap='8px' mt={3}>
              <Box direction='row' alignItems='center' display='block'>
                <Button
                  leftIcon={<RxReset />}
                  variant='primary'
                  w='88px'
                  h='33px'
                  onClick={() => {
                    resetSearch();
                    setValueSearch('from_showdate', new Date());
                    setValueSearch('to_showdate', new Date());
                  }}
                >
                  Reset
                </Button>
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <Button
                  leftIcon={<MdOutlinePublish />}
                  variant='primary'
                  w='100px'
                  h='33px'
                  onClick={() => {
                    setIsPublishInvoice(true);
                  }}
                  type='submit'
                  _hover={{ background: '#63b3ed' }}
                  disabled={listCheckedChoose.length < 1}
                >
                  Publish Invoice
                </Button>
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <Button
                  leftIcon={<BsSearch />}
                  variant='primary'
                  w='88px'
                  h='33px'
                  type='submit'
                >
                  Search
                </Button>
              </Box>
            </Flex>
          </form>
        </Card>
        <SearchTable2
          tableData={newInvoiceList}
          columnsData={columnsInvoiceList}
          totalRow={totalRows.invoice}
          listPermissions={[69]}
          actionsActive={['publish']}
          loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
          isFetching={isFetching}
          publishRow={(item) => publishInvoice(item)}
          enableRowSelection
          updateCheckbox={(val) => getValueChecked(val)}
          conditionShowSelect={`(row) => row.original.invoice_status === 0`}
          clearAllCheckBox={clearAllCheckBox}
          refreshPagingDefault={refreshPagingDefault}
        />
      </CardBody>
    </Card>
  );
}

export default DataTableInvoice;
