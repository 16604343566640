import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  Image,
  FormLabel,
  Radio,
  useToast,
  Grid,
  Stack,
  FormControl,
} from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import SearchTable2 from 'components/Tables/SearchTable2';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { columnsDataAssignSeatingPlan } from 'variables/columnsData';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { totalRow } from 'state/roles-permissions';
import { useBookingActions } from 'hooks/useBookingActions';
import { checkForbidden } from 'helpers/checkForbidden';
import { Select as ReactSelect } from 'chakra-react-select';
import {
  assignSeatingPlanListAtom,
  listUnitAtom,
  listChannelAtom,
} from 'state/show';
import { useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

function DataTableAssignSeatingPlan() {
  const chakraStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      p: 0,
      w: '20px',
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '150px',
    }),
  };
  const { seatingPlan_id } = useParams();
  const textColor = useColorModeValue('gray.700', 'white');
  const listAssignSeatingPlan = useRecoilValue(assignSeatingPlanListAtom);
  const listUnit = useRecoilValue(listUnitAtom);
  const listChannel = useRecoilValue(listChannelAtom);
  const totalRows = useRecoilValue(totalRow);
  const bookingActions = useBookingActions();
  const [legendMap, setLegendMap] = useState();
  const [seatingsLock, setSeatingsLock] = useState();
  const [desktopMap, setDesktopMap] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [newAssignSeatingPlans, setNewAssignSeatingPlans] = useState([]);
  const [ticketPrices, setTicketPrices] = useState([]);
  const [isAssign, setIsAssign] = useState(false);
  const [selectedSeat, setSelectedSeat] = useState([]);
  const [isAppliedAll, setIsAppliedAll] = useState(false);
  const colorBackGround = (zoneID) => {
    switch (zoneID) {
      case 1:
        return '#FCB515';
      case 2:
        return '#59BC67';
      case 3:
        return '#5E2F8A';
      default:
        break;
    }
  };
  const validationSchema = Yup.object().shape({
    channel: Yup.object().required('This field is required'),
    unit: Yup.object().required('This field is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState,
    reset,
    control,
    setValue,
    watch,
  } = useForm(formOptions);

  const { errors, isSubmitting } = formState;
  const toast = useToast();
  const channel = watch('channel');
  const unit = watch('unit');
  useEffect(async () => {
    const seatingPlanAssign = JSON.parse(
      localStorage.getItem('seatingPlanAssign')
    );
    bookingActions.getListChannel({ status: 1 }, 0, '');
    const {
      data: { data: ticketPrices },
    } = await bookingActions.getTicketPrices({
      show_id: seatingPlanAssign.show_id,
      venue_id: seatingPlanAssign.venue_id,
      seatingplan_id: seatingPlanAssign.seatingplan_id,
    });
    if (seatingPlanAssign) {
      setDesktopMap(`${seatingPlanAssign.desktop_maps}?type=svg`);
      setLegendMap(`${seatingPlanAssign.legend_desktop_maps}`);
    }
    setTicketPrices(ticketPrices);
    recallApi();
  }, []);
  useEffect(() => {
    if (channel) {
      setValue('unit', null);
      bookingActions.getListUnits({ channel_id: channel.id, status: 1 }, 0, '');
    }
  }, [channel]);
  useEffect(async () => {
    const data = await bookingActions.getAssignSeatBySeating(
      { status: 1, id: seatingPlan_id, unit_id: unit?.id },
      0,
      ''
    );
    const seats = data.map((i) => `lune_seat_${i.seat_number}_${i.floor}`);
    setSelectedSeat(seats);
  }, [unit]);
  useEffect(() => {
    if (listAssignSeatingPlan) {
      const newAssignListSeatingPlan = listAssignSeatingPlan.map((i) => ({
        ...i,
        typeSwitch: 'assignSeatingPlan',
      }));
      setNewAssignSeatingPlans(newAssignListSeatingPlan);
      const locks = listAssignSeatingPlan.map(
        (i) => `lune_seat_${i.seat_number}_${i.floor}`
      );
      setSeatingsLock(locks);
    }
  }, [listAssignSeatingPlan]);
  const recallApi = async () => {
    setIsFetching(true);
    await bookingActions.getAssignSeatBySeating(
      { status: 1, id: seatingPlan_id },
      0,
      ''
    );
    setIsFetching(false);
  };
  const onSubmit = async ({ channel, unit }) => {
    const seats = selectedSeat.map((seat) => {
      const part = seat.split('_');
      return {
        seat_number: part[2],
        floor: parseInt(part[3]),
      };
    });
    const payload = {
      unit_id: unit.id,
      seats,
      applied_all_showtime: isAppliedAll ? 1 : 0,
    };
    await bookingActions.updateAssignSeatingPlan(payload, seatingPlan_id);
    setIsAssign(false);
    reset();
    setSelectedSeat([]);
    await bookingActions.getAssignSeatBySeating(
      { status: 1, id: seatingPlan_id },
      0,
      ''
    );
  };
  const onSeatClick = (event) => {
    const seatNumber = event.target.getAttribute('id');
    setSelectedSeat((preState) => [...preState, seatNumber]);
    if (selectedSeat.includes(seatNumber)) {
      setSelectedSeat((preState) => preState.filter((i) => i !== seatNumber));
    }
  };
  return (
    <Card px='0px'>
      <CardHeader px='22px' mb='12px'>
        <Flex direction='column' display='block'>
          <Text
            float='left'
            color={textColor}
            fontSize='lg'
            fontWeight='bold'
            mb='6px'
          >
            Assign seat quota for unit
          </Text>
          <Stack float='right' direction='row' spacing={4}>
            {checkForbidden(42) && !isAssign && (
              <Button
                size='sm'
                onClick={() => {
                  setIsAssign(true);
                }}
                colorScheme='green'
                variant='outline'
              >
                Assign Seat
              </Button>
            )}
          </Stack>
        </Flex>
      </CardHeader>
      <CardBody pl={'22px'}>
        {isAssign ? (
          <>
            <Flex
              direction={{ sm: 'column', lg: 'row' }}
              justifyContent='space-between'
              gap={2}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl isRequired>
                  <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                    Channel
                  </FormLabel>
                  <Controller
                    name='channel'
                    control={control}
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        focusBorderColor='blue.500'
                        chakraStyles={chakraStyles}
                        options={listChannel}
                        placeholder='Select Channel'
                        getOptionLabel={(i) => i.name}
                        getOptionValue={(option) => option.id}
                        size='sm'
                        closeMenuOnSelect={true}
                      />
                    )}
                  />
                  <Text color='red' fontSize={14} mb='10px' ml='5px'>
                    {errors.channel?.message}
                  </Text>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                    Unit
                  </FormLabel>
                  <Controller
                    name='unit'
                    control={control}
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        focusBorderColor='blue.500'
                        chakraStyles={chakraStyles}
                        options={listUnit}
                        placeholder='Select Unit'
                        getOptionLabel={(i) => i.name}
                        getOptionValue={(option) => option.id}
                        size='sm'
                        closeMenuOnSelect={true}
                      />
                    )}
                  />
                  <Text color='red' fontSize={14} mb='10px' ml='5px'>
                    {errors.channel?.message}
                  </Text>
                </FormControl>
                <FormControl mb={2}>
                  <Radio
                    value={isAppliedAll}
                    onChange={(e) => setIsAppliedAll(e.target.value)}
                  >
                    Replace all showtime
                  </Radio>
                </FormControl>
                <Button
                  size='sm'
                  minW='110px'
                  colorScheme='blue'
                  ml={{ lg: 4, sm: 1 }}
                  fontSize='small'
                  type='submit'
                >
                  Save
                </Button>
                <Button
                  size='sm'
                  minW='110px'
                  colorScheme='blue'
                  ml={{ lg: 4, sm: 1 }}
                  fontSize='small'
                  onClick={async () => {
                    setIsAssign(false);
                    reset();
                    setSelectedSeat([]);
                    await bookingActions.getAssignSeatBySeating(
                      { status: 1, id: seatingPlan_id },
                      0,
                      ''
                    );
                  }}
                >
                  Cancel
                </Button>
              </form>
            </Flex>
            {unit && (
              <ReactSVG
                id='svgImage'
                src={desktopMap}
                afterInjection={(svg) => {
                  const seats = svg.querySelectorAll('circle');
                  seats.forEach((seat) => {
                    seat.addEventListener('click', onSeatClick);
                    if (selectedSeat.includes(seat.getAttribute('id'))) {
                      seat.classList.add('lockadmin');
                    }
                  });
                }}
              />
            )}
          </>
        ) : (
          <>
            <SearchTable2
              tableData={newAssignSeatingPlans}
              columnsData={columnsDataAssignSeatingPlan}
              totalRow={totalRows.assignSeatingPlans}
              listPermissions={[43]}
              isFetching={isFetching}
              loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
              manualPagination={false}
              enableRowActions={false}
              manualSorting={false}
            />
            <Flex position='relative'>
              <Stack
                position='absolute'
                p={'5px 10px'}
                backgroundColor={'white'}
                left='0'
                direction='column'
              >
                <Text fontWeight={'bold'} color='#939496' textAlign='center'>
                  LEGEND IN ZONES
                </Text>
                <Grid
                  templateColumns='repeat(3, auto)'
                  gap='6px'
                  fontSize='10px'
                  color='white'
                  textAlign='center'
                >
                  <Text color='#939496'>ZONE</Text>
                  <Text color='#939496'>SEATS</Text>
                  <Text color='#939496'>OFFICIAL RATE (VND)</Text>
                  {ticketPrices.map((i, index) => (
                    <>
                      <Text
                        key={index}
                        textAlign='center'
                        backgroundColor={colorBackGround(i.zone_id)}
                        px={3}
                        fontSize={'12px'}
                        fontWeight={'bold'}
                        py={2}
                      >
                        {i.zone.name}
                      </Text>
                      <Text
                        textAlign='center'
                        backgroundColor={colorBackGround(i.zone_id)}
                        px={3}
                        fontSize={'12px'}
                        fontWeight={'bold'}
                        py={2}
                      >
                        {i?.total_seat}
                      </Text>
                      <Text
                        px={3}
                        fontSize={'12px'}
                        fontWeight={'bold'}
                        py={2}
                        textAlign='center'
                        backgroundColor={colorBackGround(i.zone_id)}
                      >
                        {i.official_price.toLocaleString('en-US')}
                      </Text>
                    </>
                  ))}
                </Grid>
              </Stack>
              <Flex
                position='absolute'
                right='10px'
                backgroundColor='white'
                padding='10px'
              >
                {legendMap && (
                  <Image
                    maxW={{ base: '140px', md: '250px' }}
                    src={legendMap}
                  />
                )}
              </Flex>
            </Flex>
            <ReactSVG
              id='svgImage'
              src={desktopMap}
              afterInjection={(svg) => {
                const seats = svg.querySelectorAll('circle');
                seats.forEach((seat) => {
                  const finds = seatingsLock.find(
                    (i) => i.seat === seat.getAttribute('id')
                  );
                  if (finds) {
                    if (finds.status === 0) {
                      seat.classList.add('lockadmin');
                    } else if (finds.status === 1) {
                      seat.classList.add('booked_F7E305');
                    } else {
                      seat.classList.add('booked_FF0000');
                    }
                  }
                });
              }}
            />
          </>
        )}
      </CardBody>
    </Card>
  );
}

export default DataTableAssignSeatingPlan;
