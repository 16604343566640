import {
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
  Select,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { Select as ReactSelect } from 'chakra-react-select';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import DatePickerInput from 'components/CustomDateInput/CustomDateInput';
import { useBookingActions } from 'hooks/useBookingActions';
import { useRatePlanActions } from 'hooks/useRatePlanActions';
import { useReportActions } from 'hooks/useReportActions';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BsSearch } from 'react-icons/bs';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { RxReset } from 'react-icons/rx';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ratePlansAtom } from '../../state/ratePlans';
import {
  listChannelAtom,
  listShowAtom,
  listUnitAtom,
  paymentMethodListAtom,
  paymentStatusListAtom,
  showTimeListAtom,
  venueListAtom,
  zoneListAtom,
} from '../../state/show';
import { usersAtom } from '../../state/users';
import './table.scss';

function DataTableShowReport() {
  const chakraStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      p: 0,
      w: '20px',
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '200px',
      maxWidth: '400px',
    }),
    control: (provided) => ({
      ...provided,
      width: '100%',
      minWidth: '200px',
    }),
  };
  const bookingStatus = [
    { Id: '0', Name: 'All' },
    { Id: '1', Name: 'Success' },
    { Id: '2', Name: 'Cancel' },
  ];

  const listShow = useRecoilValue(listShowAtom);
  const venueList = useRecoilValue(venueListAtom);
  const listChannel = useRecoilValue(listChannelAtom);
  const listUnit = useRecoilValue(listUnitAtom);
  const listZone = useRecoilValue(zoneListAtom);
  const listPaymentMethod = useRecoilValue(paymentMethodListAtom);
  const listPaymentStatus = useRecoilValue(paymentStatusListAtom);
  const listUsers = useRecoilValue(usersAtom);
  const showTimeList = useRecoilValue(showTimeListAtom);
  const ratePlans = useRecoilValue(ratePlansAtom);
  const sortedRateplans = [...ratePlans].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const toast = useToast();
  const textColor = useColorModeValue('gray.700', 'white');
  const [bookingsSummary, setBookingsSummary] = useState([]);
  const bookingActions = useBookingActions();
  const reportActions = useReportActions();
  const ratePlanActions = useRatePlanActions();
  const [isFetching, setIsFetching] = useState(true);
  const [newBookingList, setNewBookingList] = useState([]);
  const [zoneTotals, setZoneTotals] = useState([]);
  const [zoneDeposits, setZoneDeposits] = useState([]);
  const [zoneBooked, setZoneBooked] = useState([]);
  const [zoneComplimentary, setComplimentary] = useState([]);
  const [zoneRemain, setRemain] = useState([]);
  const [zoneSale, setSale] = useState([]);
  const [isExportExcel, setIsExportExcel] = useState(false);
  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    control: controlSearch,
    watch: watchSearch,
    reset: resetSearch,
    setValue: setValueSearch,
    formState,
  } = useForm();

  const { errors, isSubmitting } = formState;
  const statusSearch = watchSearch('statusSearch');
  const showSearch = watchSearch('showSearch');
  const venueSearch = watchSearch('venueSearch');
  const unitSearch = watchSearch('unitSearch');
  const bookerNameSearch = watchSearch('bookerNameSearch');
  const bookingCodeSearch = watchSearch('bookingCodeSearch');
  const paymentMethodSearch = watchSearch('paymentMethodSearch');
  const paymentStatusSearch = watchSearch('paymentStatusSearch');
  const zoneSearch = watchSearch('zoneSearch');
  const channelSearch = watchSearch('channelSearch');
  const showTimeSearch = watchSearch('showTimeSearch');
  const showDateSearch = watchSearch('showDateSearch');
  useEffect(() => {
    if (channelSearch) {
      bookingActions.getListUnits({ channel_id: channelSearch }, 0, '');
    }
  }, [channelSearch]);

  useEffect(async () => {
    setIsFetching(true);
    const defaultValue = showDateSearch || new Date();
    const res = await reportActions.getShowReport({
      show_id: showSearch,
      venue_id: venueSearch,
      booking_code: bookingCodeSearch,
      booker_name: bookerNameSearch,
      status: statusSearch,
      channel_id: channelSearch,
      unit_id: unitSearch,
      payment_method: paymentMethodSearch,
      payment_status: paymentStatusSearch,
      show_date_from: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
      show_date_to: new Date(
        new Date().setHours(23, 59, 59, 999)
      ).toISOString(),
      show_time: showTimeSearch,
      zone_id: zoneSearch,
    });
    if (res) {
      bookingActions.getListShow({ status: 1 }, 0, '');
      bookingActions.getListChannel({ status: 1 }, 0, '');
      bookingActions.getListVenue({ status: 1 }, 0, '');
      bookingActions.getListZones({ status: 1 }, 0, '');
      bookingActions.getListPaymentStatus({ status: 1 }, 0, '');
      bookingActions.getListPaymentMethod({ status: 1 }, 0, '');
      bookingActions.getListShowTime({ status: 1 }, 0, '');
      ratePlanActions.getAllRatePlans({ status: 1 }, 0, '');
      setValueSearch('show_date_from', new Date());
      setValueSearch('show_date_to', new Date());
      setNewBookingList(res.data.data || []);
      setBookingsSummary(res.data.meta?.summary);
    }
    setIsFetching(false);
  }, []);

  const history = useHistory();
  async function onSubmitSearch({
    bookingCodeSearch,
    bookerNameSearch,
    showSearch,
    venueSearch,
    statusSearch,
    channelSearch,
    unitSearch,
    paymentMethodSearch,
    paymentStatusSearch,
    showTimeSearch,
    zoneSearch,
    userSearch,
    ratePlanSearch,
  }) {
    try {
      if (isExportExcel) {
        setIsExportExcel(false);

        const response = await reportActions.exportShowReport({
          show_id: showSearch,
          venue_id: venueSearch,
          booking_code: bookingCodeSearch,
          booker_name: bookerNameSearch,
          status: statusSearch,
          channel_id: channelSearch,
          unit_id: unitSearch,
          payment_method: paymentMethodSearch,
          payment_status: paymentStatusSearch,
          show_time: showTimeSearch,
          show_date_from: watchSearch('show_date_from')
            ? new Date(watchSearch('show_date_from').setHours(0, 0, 0, 0))
            : undefined,
          booking_date_from: watchSearch('booking_date_from')
            ? new Date(watchSearch('booking_date_from').setHours(0, 0, 0, 0))
            : undefined,
          booking_date_to: watchSearch('booking_date_to')
            ? new Date(watchSearch('booking_date_to').setHours(23, 59, 59, 999))
            : undefined,
          show_date_to: watchSearch('show_date_to')
            ? new Date(watchSearch('show_date_to').setHours(23, 59, 59, 999))
            : undefined,
          zone_id: zoneSearch,
          user_id: userSearch,
          rateplan_id: ratePlanSearch ? ratePlanSearch.id : 0,
        });
        const outputFilename = `show_report_${Date.now()}.xlsx`;
        const url = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
      } else {
        setIsFetching(true);
        const res = await reportActions.getShowReport({
          show_id: showSearch,
          venue_id: venueSearch,
          booking_code: bookingCodeSearch,
          booker_name: bookerNameSearch,
          status: statusSearch,
          channel_id: channelSearch,
          unit_id: unitSearch,
          payment_method: paymentMethodSearch,
          payment_status: paymentStatusSearch,
          show_time: showTimeSearch,
          show_date_from: watchSearch('show_date_from')
            ? new Date(watchSearch('show_date_from').setHours(0, 0, 0, 0))
            : undefined,
          booking_date_from: watchSearch('booking_date_from')
            ? new Date(watchSearch('booking_date_from').setHours(0, 0, 0, 0))
            : undefined,
          booking_date_to: watchSearch('booking_date_to')
            ? new Date(watchSearch('booking_date_to').setHours(23, 59, 59, 999))
            : undefined,
          show_date_to: watchSearch('show_date_to')
            ? new Date(watchSearch('show_date_to').setHours(23, 59, 59, 999))
            : undefined,
          zone_id: zoneSearch,
          user_id: userSearch,
          rateplan_id: ratePlanSearch ? ratePlanSearch.id : 0,
        });
        setNewBookingList(res.data.data || []);
        setBookingsSummary(res.data.meta?.summary);
      }
    } catch (error) {
      toast({
        description: `${error}`,
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsFetching(false);
    }
  }
  useEffect(() => {
    const propertiesToCalculate = [
      { property: 'amount', stateSetter: setZoneTotals },
      { property: 'complimentary', stateSetter: setComplimentary },
      { property: 'deposit', stateSetter: setZoneDeposits },
      { property: 'booked', stateSetter: setZoneBooked },
      { property: 'remaining', stateSetter: setRemain },
      { property: 'sale', stateSetter: setSale },
    ];

    propertiesToCalculate.forEach(({ property, stateSetter }) => {
      if (bookingsSummary?.[property] && bookingsSummary[property].length > 0) {
        stateSetter(calculateZoneTotals(bookingsSummary[property]));
      } else {
        stateSetter({
          1: 0,
          2: 0,
          3: 0,
        });
      }
    });
  }, [bookingsSummary]);
  function calculateZoneTotals(data = []) {
    const zoneTotals = {};

    data.forEach((item) => {
      zoneTotals[item.zone_id] = item.total;
    });

    for (let zone_id = 1; zone_id <= 3; zone_id++) {
      if (!zoneTotals.hasOwnProperty(zone_id)) {
        zoneTotals[zone_id] = 0;
      }
    }

    return zoneTotals;
  }

  return (
    <Card px='0px'>
      <CardHeader px='22px' mb='12px'>
        <Flex direction='column' display='block'>
          <Text
            float='left'
            color={textColor}
            fontSize='lg'
            fontWeight='bold'
            mb='6px'
          >
            Show Report
          </Text>
        </Flex>
      </CardHeader>
      <CardBody>
        <Flex ml='22px'></Flex>
        <Card>
          <form
            id='booking-search-form'
            onSubmit={handleSubmitSearch(onSubmitSearch)}
          >
            <Flex
              direction='row'
              alignItems='center'
              mb={3}
              gap={4}
              flexWrap={'wrap'}
            >
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                  Show
                </FormLabel>
                <Select
                  ms='4px'
                  type='text'
                  defaultValue='0'
                  size='sm'
                  id='showSearch'
                  name='showSearch'
                  {...registerSearch('showSearch')}
                  width='100px'
                >
                  <option value=''>All</option>
                  {listShow.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                  Venue
                </FormLabel>
                <Select
                  ms='4px'
                  type='text'
                  size='sm'
                  id={'venueSearch'}
                  name='venueSearch'
                  {...registerSearch('venueSearch')}
                >
                  <option value=''>All</option>
                  {venueList.map((i) => (
                    <option key={i.id} value={i.id}>
                      {i.name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                  Show Date From
                </FormLabel>
                <Controller
                  name='show_date_from'
                  control={controlSearch}
                  render={({ field }) => (
                    <DatePickerInput
                      onChange={(value) => field.onChange(value)}
                      mask='99/99/9999'
                      selected={watchSearch('show_date_from')}
                      selectsStart
                      endDate={watchSearch('show_date_to')}
                      minDate={new Date('01/01/2020')}
                    />
                  )}
                />
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                  Show Date To
                </FormLabel>
                <Controller
                  name='show_date_to'
                  control={controlSearch}
                  render={({ field }) => (
                    <DatePickerInput
                      onChange={(value) => field.onChange(value)}
                      selected={watchSearch('show_date_to')}
                      mask='99/99/9999'
                      selectsEnd
                      endDate={watchSearch('show_date_to')}
                      minDate={watchSearch('show_date_from')}
                    />
                  )}
                />
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                  Show Time
                </FormLabel>
                <Select
                  ms='4px'
                  type='text'
                  defaultValue=''
                  size='sm'
                  name='showTimeSearch'
                  {...registerSearch('showTimeSearch')}
                >
                  <option value=''>All</option>
                  {showTimeList.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                  Channel
                </FormLabel>
                <Select
                  ms='4px'
                  type='text'
                  size='sm'
                  name='channelSearch'
                  {...registerSearch('channelSearch')}
                  width='200px'
                >
                  <option value=''>All</option>
                  {listChannel.map((i) => (
                    <option key={i.id} value={i.id}>
                      {i.name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                  Unit
                </FormLabel>
                <Select
                  ms='4px'
                  type='text'
                  size='sm'
                  id='unit_search'
                  name='unitSearch'
                  {...registerSearch('unitSearch')}
                >
                  <option value=''>All</option>
                  {listUnit.map((i) => (
                    <option key={i.id} value={i.id}>
                      {i.name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                  Person In Charge
                </FormLabel>
                <Select
                  ms='4px'
                  type='text'
                  defaultValue=''
                  size='sm'
                  name='userSearch'
                  {...registerSearch('userSearch')}
                >
                  <option value=''>All</option>
                  {listUsers.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.fullname}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                  Zone
                </FormLabel>
                <Select
                  ms='4px'
                  type='text'
                  size='sm'
                  name='zoneSearch'
                  {...registerSearch('zoneSearch')}
                  width='200px'
                >
                  <option value=''>All</option>
                  {listZone.map((i) => (
                    <option key={i.id} value={i.id}>
                      {i.name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel
                  htmlFor='role-search-lbl'
                  fontWeight='semibold'
                  fontSize='xs'
                  ms='4px'
                >
                  Payment Status
                </FormLabel>
                <Select
                  ms='4px'
                  type='text'
                  defaultValue=''
                  size='sm'
                  name='paymentStatusSearch'
                  {...registerSearch('paymentStatusSearch')}
                  width='100px'
                >
                  <option value=''>All</option>
                  {listPaymentStatus.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                  Payment Method
                </FormLabel>
                <Select
                  ms='4px'
                  type='text'
                  placeholder=''
                  size='sm'
                  name='paymentMethodSearch'
                  {...registerSearch('paymentMethodSearch')}
                >
                  <option value=''>All</option>
                  {listPaymentMethod.map((item, index) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                  Booking Status
                </FormLabel>
                <Select
                  ms='4px'
                  type='text'
                  defaultValue='0'
                  size='sm'
                  id='statusSearch'
                  name='statusSearch'
                  {...registerSearch('statusSearch')}
                  width='100px'
                >
                  {bookingStatus.map((item, index) => (
                    <option key={index} value={item.Id}>
                      {item.Name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel ms='4px' fontSize='xs' fontWeight='semibold'>
                  Booking Code
                </FormLabel>
                <Input
                  _placeholder={{ color: 'gray.400' }}
                  variant='main'
                  type='text'
                  placeholder='Booking Code'
                  width='200px'
                  size='sm'
                  fontSize='sm'
                  name='bookingCodeSearch'
                  {...registerSearch('bookingCodeSearch')}
                  _focus={{ borderColor: 'blue.500' }}
                />
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel ms='4px' fontSize='xs' fontWeight='semibold'>
                  Booker Name
                </FormLabel>
                <Input
                  _placeholder={{ color: 'gray.400' }}
                  variant='main'
                  type='text'
                  placeholder='Booker Name,...'
                  minW='80px'
                  maxW='185px'
                  size='sm'
                  fontSize='sm'
                  name='bookerNameSearch'
                  {...registerSearch('bookerNameSearch')}
                  _focus={{ borderColor: 'blue.500' }}
                />
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                  Booked Date From
                </FormLabel>
                <Controller
                  name='booking_date_from'
                  control={controlSearch}
                  render={({ field }) => (
                    <DatePickerInput
                      onChange={(value) => field.onChange(value)}
                      mask='99/99/9999'
                      selected={watchSearch('booking_date_from')}
                      selectsStart
                      endDate={watchSearch('booking_date_to')}
                      minDate={new Date('01/01/2020')}
                    />
                  )}
                />
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                  Booked Date To
                </FormLabel>
                <Controller
                  name='booking_date_to'
                  control={controlSearch}
                  render={({ field }) => (
                    <DatePickerInput
                      onChange={(value) => field.onChange(value)}
                      selected={watchSearch('booking_date_to')}
                      mask='99/99/9999'
                      selectsEnd
                      endDate={watchSearch('booking_date_to')}
                      minDate={watchSearch('booking_date_from')}
                    />
                  )}
                />
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                  Rate Plan
                </FormLabel>

                <Controller
                  name='ratePlanSearch'
                  control={controlSearch}
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      focusBorderColor='blue.500'
                      chakraStyles={chakraStyles}
                      options={sortedRateplans}
                      placeholder='Select Rateplan'
                      getOptionLabel={(i) => i.name}
                      getOptionValue={(option) => option.id}
                      size='sm'
                      closeMenuOnSelect={true}
                    />
                  )}
                />
              </Box>
            </Flex>
            <Text color='red' fontSize={14}>
              {Object.values(errors)[0]?.message}
            </Text>
            <Flex justify='center' align='center' gap='8px' mt={3}>
              <Box direction='row' alignItems='center' display='block'>
                <Button
                  leftIcon={<RxReset />}
                  variant='primary'
                  w='88px'
                  h='33px'
                  onClick={() => {
                    resetSearch();
                    setValueSearch('showDateFromSearch', new Date());
                    setValueSearch('showDateToSearch', new Date());
                    setValueSearch('bookingDateFromSearch', new Date());
                    setValueSearch('bookingDateToSearch', new Date());
                  }}
                >
                  Reset
                </Button>
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <Button
                  leftIcon={<RiFileExcel2Fill />}
                  variant='primary'
                  w='100px'
                  h='33px'
                  onClick={() => {
                    setIsExportExcel(true);
                  }}
                  type='submit'
                >
                  Export Excel
                </Button>
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <Button
                  leftIcon={<BsSearch />}
                  variant='primary'
                  w='88px'
                  h='33px'
                  type='submit'
                >
                  Search
                </Button>
              </Box>
            </Flex>
          </form>
        </Card>
        <Table size='sm'>
          <Thead>
            <Tr>
              <Th color='white' colSpan='5' backgroundColor='blue.600'>
                SUMMARY
              </Th>
            </Tr>
            <Tr>
              <Th w={'200px'}></Th>
              <Th textAlign='center'>Total</Th>
              <Th textAlign='center'>aah![A]</Th>
              <Th textAlign='center'>ooh![O]</Th>
              <Th textAlign='center'>wow![W]</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Complimentary</Td>
              {Object.keys(zoneComplimentary).length > 0 ? (
                <>
                  <Td textAlign='center'>
                    {(
                      zoneComplimentary[1] +
                      zoneComplimentary[2] +
                      zoneComplimentary[3]
                    ).toLocaleString('en-US')}
                  </Td>
                  <Td textAlign='center'>
                    {zoneComplimentary[1].toLocaleString('en-US')}
                  </Td>
                  <Td textAlign='center'>
                    {zoneComplimentary[2].toLocaleString('en-US')}
                  </Td>
                  <Td textAlign='center'>
                    {zoneComplimentary[3].toLocaleString('en-US')}
                  </Td>
                </>
              ) : (
                <>
                  <Td textAlign='center'>0</Td>
                  <Td textAlign='center'>0</Td>
                  <Td textAlign='center'>0</Td>
                  <Td textAlign='center'>0</Td>
                </>
              )}
            </Tr>
            <Tr>
              <Td>Sale</Td>
              {Object.keys(zoneSale).length > 0 ? (
                <>
                  <Td textAlign='center'>
                    {(zoneSale[1] + zoneSale[2] + zoneSale[3]).toLocaleString(
                      'en-US'
                    )}
                  </Td>
                  <Td textAlign='center'>
                    {zoneSale[1].toLocaleString('en-US')}
                  </Td>
                  <Td textAlign='center'>
                    {zoneSale[2].toLocaleString('en-US')}
                  </Td>
                  <Td textAlign='center'>
                    {zoneSale[3].toLocaleString('en-US')}
                  </Td>
                </>
              ) : (
                <>
                  <Td textAlign='center'>0</Td>
                  <Td textAlign='center'>0</Td>
                  <Td textAlign='center'>0</Td>
                  <Td textAlign='center'>0</Td>
                </>
              )}
            </Tr>
          </Tbody>
          <tfoot>
            <Tr>
              <Th>Total Booked</Th>
              {Object.keys(zoneBooked).length > 0 ? (
                <>
                  <Td textAlign='center'>
                    {(
                      zoneBooked[1] +
                      zoneBooked[2] +
                      zoneBooked[3]
                    ).toLocaleString('en-US')}
                  </Td>
                  <Td textAlign='center'>
                    {zoneBooked[1].toLocaleString('en-US')}
                  </Td>
                  <Td textAlign='center'>
                    {zoneBooked[2].toLocaleString('en-US')}
                  </Td>
                  <Td textAlign='center'>
                    {zoneBooked[3].toLocaleString('en-US')}
                  </Td>
                </>
              ) : (
                <>
                  <Td textAlign='center'>0</Td>
                  <Td textAlign='center'>0</Td>
                  <Td textAlign='center'>0</Td>
                  <Td textAlign='center'>0</Td>
                </>
              )}
            </Tr>
            <Tr>
              <Th>Total Amount</Th>
              {Object.keys(zoneTotals).length > 0 ? (
                <>
                  <Td textAlign='center'>
                    {(
                      zoneTotals[1] +
                      zoneTotals[2] +
                      zoneTotals[3]
                    ).toLocaleString('en-US')}
                  </Td>
                  <Td textAlign='center'>
                    {zoneTotals[1].toLocaleString('en-US')}
                  </Td>
                  <Td textAlign='center'>
                    {zoneTotals[2].toLocaleString('en-US')}
                  </Td>
                  <Td textAlign='center'>
                    {zoneTotals[3].toLocaleString('en-US')}
                  </Td>
                </>
              ) : (
                <>
                  <Td textAlign='center'>0</Td>
                  <Td textAlign='center'>0</Td>
                  <Td textAlign='center'>0</Td>
                  <Td textAlign='center'>0</Td>
                </>
              )}
            </Tr>
            <Tr>
              <Th>Total Deposit</Th>
              {Object.keys(zoneDeposits).length > 0 ? (
                <>
                  <Td textAlign='center'>
                    {(
                      zoneDeposits[1] +
                      zoneDeposits[2] +
                      zoneDeposits[3]
                    ).toLocaleString('en-US')}
                  </Td>
                  <Td textAlign='center'>
                    {zoneDeposits[1].toLocaleString('en-US')}
                  </Td>
                  <Td textAlign='center'>
                    {zoneDeposits[2].toLocaleString('en-US')}
                  </Td>
                  <Td textAlign='center'>
                    {zoneDeposits[3].toLocaleString('en-US')}
                  </Td>
                </>
              ) : (
                <>
                  <Td textAlign='center'>0</Td>
                  <Td textAlign='center'>0</Td>
                  <Td textAlign='center'>0</Td>
                </>
              )}
            </Tr>
            <Tr>
              <Th>Total Remaining</Th>
              {Object.keys(zoneRemain).length > 0 ? (
                <>
                  <Td textAlign='center'>
                    {(
                      zoneRemain[1] +
                      zoneRemain[2] +
                      zoneRemain[3]
                    ).toLocaleString('en-US')}
                  </Td>
                  <Td textAlign='center'>
                    {zoneRemain[1].toLocaleString('en-US')}
                  </Td>
                  <Td textAlign='center'>
                    {zoneRemain[2].toLocaleString('en-US')}
                  </Td>
                  <Td textAlign='center'>
                    {zoneRemain[3].toLocaleString('en-US')}
                  </Td>
                </>
              ) : (
                <>
                  <Td textAlign='center'>0</Td>
                  <Td textAlign='center'>0</Td>
                  <Td textAlign='center'>0</Td>
                </>
              )}
            </Tr>
          </tfoot>
        </Table>
        {isFetching ? (
          <Flex justify='center' align='center' my='20px'>
            <Spinner
              textAlign='center'
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
          </Flex>
        ) : (
          <div
            className='content'
            dangerouslySetInnerHTML={{ __html: newBookingList }}
          ></div>
        )}
      </CardBody>
    </Card>
  );
}

export default DataTableShowReport;
