const PanelContainer = {
  baseStyle: {
    minHeight: "100vh",
    borderTop: "1px solid black",
  },
};

export const PanelContainerComponent = {
  components: {
    PanelContainer,
  },
};
