import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Switch,
  Stack,
  Grid,
  Image,
  Tab,
  TabList,
  Tabs,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import SearchTable2 from 'components/Tables/SearchTable2';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { totalRow } from 'state/roles-permissions';
import { AddIcon } from '@chakra-ui/icons';
import { checkForbidden } from 'helpers/checkForbidden';
import { useCMSActions } from 'hooks/useCMS';
import { useParams } from 'react-router-dom';
import { columnsDataBannerImages } from 'variables/columnsData';
import { useRatePlanActions } from 'hooks/useRatePlanActions';
import { findValue } from 'helpers/helpers';

function DataTableBannerImages() {
  const { banner_id } = useParams();
  const textColor = useColorModeValue('gray.700', 'white');
  const totalRows = useRecoilValue(totalRow);
  const cmsActions = useCMSActions();
  const ratePlanActions = useRatePlanActions();
  const {
    isOpen: isOpenImages,
    onOpen: onOpenImages,
    onClose: onCloseImages,
  } = useDisclosure();

  const [isCreate, setIsCreate] = useState(true);
  const [currentVenueWeb, setCurrentVenueWeb] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [newVenueWeb, setNewVenueWeb] = useState([]);
  const [images, setImages] = useState({
    image_url: '',
    medium_image_url: '',
    thumbnail_image_url: '',
  });
  const [imagesVN, setImagesVN] = useState({
    image_url_vn: '',
    medium_image_url_vn: '',
    thumbnail_image_url_vn: '',
  });
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit: handleSubmitImages,
    formState,
    reset,
    setValue,
  } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const toast = useToast();
  useEffect(async () => {
    setIsFetching(true);
    const data = await cmsActions.getBannerDetail(banner_id);
    setNewVenueWeb(data.data.data.images);
    setIsFetching(false);
  }, []);

  async function onSubmitImages({ name, url, isActive }) {
    const params = {
      name,
      banner_id: parseInt(banner_id),
      url,
      status: isActive ? 1 : 2,
      image_url: images.image_url,
      medium_image_url: images.medium_image_url,
      thumbnail_image_url: images.thumbnail_image_url,
    };
    const listKeys = ['thumbnail_image_url', 'medium_image_url', 'image_url'];
    const languages = listKeys
      .map((i) => {
        if (i === 'thumbnail_image_url') {
          return {
            code: 'vi',
            locale_key: i,
            locale_value: imagesVN.thumbnail_image_url_vn,
          };
        } else if (i === 'image_url') {
          return {
            code: 'vi',
            locale_key: i,
            locale_value: imagesVN.image_url_vn,
          };
        } else if (i === 'medium_image_url') {
          return {
            code: 'vi',
            locale_key: i,
            locale_value: imagesVN.medium_image_url_vn,
          };
        }
      })
      .filter(Boolean);
    params.languages = languages;
    try {
      let res;
      if (isCreate) {
        res = await cmsActions.addBannerImage(params);
      } else {
        res = await cmsActions.updateBannerImage(params, currentVenueWeb);
      }

      if (res.status === 200) {
        onCloseImages();
        reset();
        const message = isCreate
          ? 'Create Banner Images success'
          : 'Update Banner Images success';
        toast({
          description: message,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        const data = await cmsActions.getBannerDetail(banner_id);
        setNewVenueWeb(data.data.data.images);
        setImages({
          image_url: '',
          medium_image_url: '',
          thumbnail_image_url: '',
        });
        setImagesVN({
          image_url_vn: '',
          medium_image_url_vn: '',
          thumbnail_image_url_vn: '',
        });
      }
    } catch (err) {
      console.warn(err);
      toast({
        question: err.response,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }
  const changeImage = async (val, type) => {
    const formData = new FormData();
    formData.append('file', val.target.files[0]);
    const {
      data: { data },
    } = await ratePlanActions.uploadImageRatePlanConfig(formData);
    const url = data.url;
    switch (type) {
      case 1:
        setImages((preS) => ({ ...preS, image_url: url }));
        break;
      case 2:
        setImages((preS) => ({ ...preS, medium_image_url: url }));
        break;
      case 3:
        setImages((preS) => ({ ...preS, thumbnail_image_url: url }));
        break;
      default:
        break;
    }
  };
  const changeImageVN = async (val, type) => {
    const formData = new FormData();
    formData.append('file', val.target.files[0]);
    const {
      data: { data },
    } = await ratePlanActions.uploadImageRatePlanConfig(formData);
    const url = data.url;
    switch (type) {
      case 1:
        setImagesVN((preS) => ({ ...preS, image_url_vn: url }));
        break;
      case 2:
        setImagesVN((preS) => ({ ...preS, medium_image_url_vn: url }));
        break;
      case 3:
        setImagesVN((preS) => ({ ...preS, thumbnail_image_url_vn: url }));
        break;
      default:
        break;
    }
  };
  async function editNews(idNews) {
    const {
      data: { data: newData },
    } = await cmsActions.getBannerImageDetail(idNews);
    if (newData) {
      const {
        name,
        status,
        id,
        url,
        image_url,
        thumbnail_image_url,
        medium_image_url,
        languages,
      } = newData;
      setValue('name', name);
      setValue('url', url);
      setImages((preS) => ({ ...preS, image_url: image_url }));
      setImages((preS) => ({ ...preS, medium_image_url: medium_image_url }));
      setImages((preS) => ({
        ...preS,
        thumbnail_image_url: thumbnail_image_url,
      }));
      if (languages) {
        setImagesVN((preS) => ({
          ...preS,
          medium_image_url_vn: findValue(languages, 'medium_image_url'),
        }));
        setImagesVN((preS) => ({
          ...preS,
          image_url_vn: findValue(languages, 'image_url'),
        }));
        setImagesVN((preS) => ({
          ...preS,
          thumbnail_image_url_vn: findValue(languages, 'thumbnail_image_url'),
        }));
      }
      setValue('isActive', status === 1 ? true : false);
      setCurrentVenueWeb(id);
    }
    setIsCreate(false);
    onOpenImages();
  }

  return (
    <Card px='0px'>
      <CardHeader px='22px' mb='12px'>
        <Flex direction='column' display='block'>
          <Text
            float='left'
            color={textColor}
            fontSize='lg'
            fontWeight='bold'
            mb='6px'
          >
            Banner Images Management
          </Text>
          <Stack float='right' direction='row' spacing={4}>
            {checkForbidden(42) && (
              <Button
                size='sm'
                onClick={() => {
                  onOpenImages();
                  setIsCreate(true);
                }}
                leftIcon={<AddIcon />}
                colorScheme='green'
                variant='outline'
              >
                Add Banner Images
              </Button>
            )}
          </Stack>
          <Modal
            closeOnOverlayClick={false}
            isOpen={isOpenImages}
            onClose={() => {
              onCloseImages();
              reset();
              setImages({
                image_url: '',
                medium_image_url: '',
                thumbnail_image_url: '',
              });
              setImagesVN({
                image_url_vn: '',
                medium_image_url_vn: '',
                thumbnail_image_url_vn: '',
              });
            }}
            size='2xl'
          >
            <ModalOverlay />
            <form onSubmit={handleSubmitImages(onSubmitImages)}>
              <ModalContent>
                <ModalHeader>
                  {isCreate ? 'Add Banner Images' : 'Edit Banner Images'}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                  <Tabs isFitted variant='enclosed'>
                    <TabList>
                      <Tab
                        _focus={{ outline: 'none' }}
                        _selected={{ color: 'white', bg: 'blue.500' }}
                        fontWeight={'bold'}
                        fontSize={'18px'}
                      >
                        English
                      </Tab>
                      <Tab
                        _focus={{ outline: 'none' }}
                        _selected={{ color: 'white', bg: 'blue.500' }}
                        fontWeight={'bold'}
                        fontSize={'18px'}
                      >
                        Viet Nam
                      </Tab>
                    </TabList>

                    <TabPanels>
                      <TabPanel>
                        <Grid
                          templateColumns={{
                            sm: '1fr',
                            md: 'repeat(2, 1fr)',
                          }}
                          gap='12px'
                          mt={2}
                        >
                          <FormControl isRequired>
                            <FormLabel
                              ms='4px'
                              fontSize='sm'
                              fontWeight='normal'
                            >
                              Name
                            </FormLabel>
                            <Input
                              fontSize='sm'
                              ms='4px'
                              type='text'
                              placeholder='Name'
                              maxLength={250}
                              size='md'
                              name='name'
                              {...register('name')}
                            />
                          </FormControl>
                          <FormControl>
                            <FormLabel
                              ms='4px'
                              fontSize='sm'
                              fontWeight='normal'
                            >
                              Url
                            </FormLabel>
                            <Input
                              fontSize='sm'
                              ms='4px'
                              type='text'
                              placeholder='Url'
                              maxLength={250}
                              size='md'
                              name='url'
                              {...register('url')}
                            />
                          </FormControl>
                        </Grid>
                        <FormControl mt={4} mb={2}>
                          <FormLabel
                            color={textColor}
                            fontWeight='bold'
                            fontSize='xs'
                          >
                            Image URL
                          </FormLabel>

                          <Input
                            fontSize='sm'
                            ms='4px'
                            type='file'
                            size='sm'
                            onChange={(val) => changeImage(val, 1)}
                          />
                          {images.image_url && (
                            <Image src={images.image_url} m={2} />
                          )}
                        </FormControl>
                        <FormControl mt={4} mb={2}>
                          <FormLabel
                            color={textColor}
                            fontWeight='bold'
                            fontSize='xs'
                          >
                            Medium Image Url
                          </FormLabel>
                          <Input
                            fontSize='sm'
                            ms='4px'
                            type='file'
                            size='sm'
                            name='mobile_maps'
                            onChange={(val) => changeImage(val, 2)}
                          />
                          {images.medium_image_url && (
                            <Image src={images.medium_image_url} />
                          )}
                        </FormControl>
                        <FormControl mt={4} mb={2}>
                          <FormLabel
                            color={textColor}
                            fontWeight='bold'
                            fontSize='xs'
                          >
                            Thumbnail Image Url
                          </FormLabel>
                          <Input
                            fontSize='sm'
                            ms='4px'
                            type='file'
                            size='sm'
                            onChange={(val) => changeImage(val, 3)}
                          />
                          {images.thumbnail_image_url && (
                            <Image src={images.thumbnail_image_url} />
                          )}
                        </FormControl>
                      </TabPanel>
                      <TabPanel>
                        <FormControl mt={4} mb={2}>
                          <FormLabel
                            color={textColor}
                            fontWeight='bold'
                            fontSize='xs'
                          >
                            Image URL
                          </FormLabel>

                          <Input
                            fontSize='sm'
                            ms='4px'
                            type='file'
                            size='sm'
                            onChange={(val) => changeImageVN(val, 1)}
                          />
                          {imagesVN.image_url_vn && (
                            <Image src={imagesVN.image_url_vn} m={2} />
                          )}
                        </FormControl>
                        <FormControl mt={4} mb={2}>
                          <FormLabel
                            color={textColor}
                            fontWeight='bold'
                            fontSize='xs'
                          >
                            Medium Image Url
                          </FormLabel>
                          <Input
                            fontSize='sm'
                            ms='4px'
                            type='file'
                            size='sm'
                            name='mobile_maps'
                            onChange={(val) => changeImageVN(val, 2)}
                          />
                          {imagesVN.medium_image_url_vn && (
                            <Image src={imagesVN.medium_image_url_vn} />
                          )}
                        </FormControl>
                        <FormControl mt={4} mb={2}>
                          <FormLabel
                            color={textColor}
                            fontWeight='bold'
                            fontSize='xs'
                          >
                            Thumbnail Image Url
                          </FormLabel>
                          <Input
                            fontSize='sm'
                            ms='4px'
                            type='file'
                            size='sm'
                            onChange={(val) => changeImageVN(val, 3)}
                          />
                          {imagesVN.thumbnail_image_url_vn && (
                            <Image src={imagesVN.thumbnail_image_url_vn} />
                          )}
                        </FormControl>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>

                  <FormControl>
                    <Switch
                      id='isChecked'
                      defaultChecked={isCreate && true}
                      name='isActive'
                      {...register('isActive')}
                      size='sm'
                      ms='4px'
                    >
                      Active
                    </Switch>
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme='blue'
                    mr={3}
                    type='submit'
                    isLoading={isSubmitting}
                  >
                    {isCreate ? 'Create' : 'Update'}
                  </Button>
                  <Button
                    onClick={() => {
                      onCloseImages();
                      reset();
                      setImages({
                        image_url: '',
                        medium_image_url: '',
                        thumbnail_image_url: '',
                      });
                      setImagesVN({
                        image_url_vn: '',
                        medium_image_url_vn: '',
                        thumbnail_image_url_vn: '',
                      });
                    }}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          </Modal>
        </Flex>
      </CardHeader>
      <CardBody>
        <SearchTable2
          tableData={newVenueWeb}
          columnsData={columnsDataBannerImages}
          totalRow={totalRows.banner}
          edit={editNews}
          actionsActive={['edit']}
          listPermissions={[43]}
          isFetching={isFetching}
          manualSorting={false}
          manualPagination={false}
        />
      </CardBody>
    </Card>
  );
}

export default DataTableBannerImages;
